
import {Component, Vue} from "vue-property-decorator";
import {ProgramPaths} from "@/enum/ProgramPaths";

interface ExcelHeader {
  field: string;
  callback?: (value: any) => any;
}

@Component({
  components: {},
})

export default class RaporDetayView extends Vue {
  headers: Array<object> = [];
  excelHeaders: { [headerName: string]: string | ExcelHeader } = {};
  items!: Array<object>;
  isReady: boolean = false;
  raporAdi: string = "";
  programPaths = ProgramPaths;
  printMode = false;

  mounted() {
    this.isReady = false;
    this.load();
  }

  load() {
    this.$http.get('/api/v1/rapor/' + this.$route.params.id).then((response) => {
      this.raporAdi = response.data.isim;
      response.data.alanlar.forEach((raporAlani: IRaporAlani) => {
        this.headers.push({text: raporAlani.rapor_alan.isim, value: raporAlani.rapor_alan.kod});
        if (["harc_tutari", "masraf_toplami", "masraf_tutari", "tahsilat_toplami", "tahsilat_tutari"].indexOf(raporAlani.rapor_alan.kod) > -1) {
          this.excelHeaders[raporAlani.rapor_alan.isim] = {
            field: raporAlani.rapor_alan.kod,
            callback: (value) => {
              return value.replace('.', ',');
            }
          }
        } else
          this.excelHeaders[raporAlani.rapor_alan.isim] = raporAlani.rapor_alan.kod;
      });
      this.$http.post('/api/v1/rapor/' + this.$route.params.id + '/render').then((response) => {
        this.items = response.data;
        this.isReady = true;
      })
    })
  }

  ciktiAl() {
    this.printMode = true;
    this.$nextTick(() => {
      this.$helper.printComponent('rapor');
      this.printMode = false;
    });
  }
}

interface IRaporAlani {
  gosterim_sirasi: number,
  id: number,
  rapor_alan: IRaporAlan,
  rapor_alan_id: number,
  rapor_id: number
}

interface IRaporAlan {
  id: number,
  isim: string,
  kod: string,
  field: string,
  joins: string,
  kosul_input: string,
  kosul_olabilir_mi: boolean,
  kosul_operatorleri: Array<any>
}
